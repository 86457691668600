<template>
  <div class="view pa24">
    <table class="align-cen-table textc table table-hover table-bordered bgf6f9fa fixed-table text-ellipsis"
            v-if="accountMap[2] && accountMap[2].state == 1">
      <thead>
      <tr>
        <th>小程序名称</th>
        <th>APPID</th>
        <th>授权时间</th>
      </tr>
      </thead>
      <tbody class="bgfff">
      <tr>
        <td>{{accountMap[2].appName}}</td>
        <td>{{accountMap[2].appId}}</td>
        <td>{{accountMap[2].createTime | getDataTimeSec('yyyy-MM-dd hh:mm:ss')}}</td>
      </tr>
      </tbody>
    </table>
    <el-form
      v-else
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-position="top"
      class="demo-ruleForm"
    >
      <el-form-item label="App名称" prop="appName">
        <el-col :lg="11">
          <el-input
            placeholder="请输入小程序名称"
            v-model="ruleForm.appName"
          ></el-input>
          <p>
            小程序的名称（请于微信小程序平台中的小程序名称保持一致，进入【设置】--【基本设置】--【小程序名称】查看）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="appId" prop="appId">
        <el-col :lg="11">
          <el-input
            type="text"
            placeholder="请输入小程序AppId"
            v-model="ruleForm.appId"
          ></el-input>
          <p>
            登录小程序后台（
            <a style="color:#20aee3" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
            ）进入【设置】--【开发设置】--【开发设置AppID,wx开头18位字符】）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="AppSecret" prop="appSecret">
        <el-col :lg="11">
          <el-input
            placeholder="请输入小程序AppSecret"
            type="text"
            v-model="ruleForm.appSecret"
          ></el-input>
          <p>
            登录小程序后台（
            <a style="color:#20aee3" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
            ）进入【设置】--【开发设置】--【AppSecret（小程序密钥)】--生成密钥（32位字符）
          </p>
        </el-col>
      </el-form-item>
      <el-form-item label="AppSecret" prop="AppSecret">
        <el-col :lg="11">
          <el-button type="primary" @click="authorization">授权</el-button>
        </el-col>
      </el-form-item>
    </el-form>
    <div class="pl30 pt10">
      <p class="cA2 mb10">请前往智能名片 【个人中心】-- 【用户ID】</p>
      <p class="mb10"><span class="corange ">*</span>用户ID</p>
      <el-input
            placeholder="请输入智能名片用户ID"
            style="width:600px;margin-bottom:10px"
            type="text"
            v-model="userId"
          ></el-input>
      <p class="corange mb10">请确认所有客户都是共享客户才可进行转移</p>
      <p class="cA2 mb10">目前只支持，转移 企业信息，产品信息，官网，共享客户这4种数据。不是支持员工转移。 员工只能重新加入新的小程序。</p>
      <el-button type="primary" @click="companyTransfer">确认转移</el-button>
      <el-button type="primary" @click="resetAuthorization">重置授权</el-button>
    </div>
  </div>
</template>

<script>
import {componentloginpage , companyTransfer} from "@/api/authorization"
import {componentCallBack} from '@/api/wechat'
import { getDataTimeSec } from "@/utils";
export default {
  props:{
    accountMap: {
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {
        appName: "",
        appId: "",
        appSecret: "",
      },
      rules: {
        appName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序AppId", trigger: "blur" },
        ],
        appSecret: [
          { required: true, message: "请输入小程序AppSecret", trigger: "blur" },
        ],
      },
      userId:'',
      idMap:{
        1: 'userApp',
        2: 'crmApp',
        3: 'pcData',
        4: 'payInfo',
      },
    };
  },
  created(){
    let than = this
    let crmInfo = JSON.parse(localStorage.getItem('crmInfo')) || ''
    if(crmInfo){
      this.ruleForm = crmInfo
    }

    console.log(this.accountMap)
    if(this.$route.query.auth_code){
      //oem授权回调
      let crmAppId = localStorage.getItem('crmAppId')
      if(crmAppId){
        componentCallBack({authCode:this.$route.query.auth_code,appId:localStorage.getItem('crmAppId')}).then(res =>{
          localStorage.removeItem('crmAppId');
          this.getAppInfo()
        });
      }

    }
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  computed:{
    checkAppSecret(){
     return  this.ruleForm.AppSecret ? '' : 'password'
    }
  },
  methods:{
    authorization(){
      let data = {
        appName: this.ruleForm.appName,
        appId:this.ruleForm.appId,
        appSecret:this.ruleForm.appSecret,
        accountType:2 //crm2
      }
      componentloginpage(data)
      .then(res=>{
        localStorage.setItem('crmAppId',this.ruleForm.appId)
        window.open(res.data);
      })
    },
    getAppInfo(){
      this.$emit('getAppListInfo')
    },
    companyTransfer(){
      if (!this.userId) {
          this.$message({
              type: "warning",
              message: "请输入用户id",
            });
          return;
      }
      this.$confirm("确定要将企业移交给该用户吗？?", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        companyTransfer({userId: this.userId})
        .then(res=>{
          console.log(res)
          if(res.code == 200){
            this.$message({
              type: "success",
              message: "转移成功",
            });
          } else {
            this.$message({
              message: "转移失败！" + res.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err)
          if(err.code == 200){
            this.$message({
              type: "success",
              message: "转移成功",
            });
          } else {
            this.$message({
              message: "转移失败！" + err.message,
              type: "error",
            });
          }
        })
      })
      .catch((err) => {});
    },
    resetAuthorization(){
      let accountId = ''
      for(var i in this.accountMap){
        if(this.accountMap[i].accountType == 2){
            accountId = this.accountMap[i].accountId
        }
      }
      if(!accountId){
        this.$message.error('还未进行授权')
        return 
      }
      this.$emit('resetAuthorization',accountId)
    }
  }
};
</script>

<style lang="scss" scoped>
table th{
  background: #F7FAFA;
}
tbody:hover{
  background: #F7FAFA;
}
table tr{
  height: 50px;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fixed-table {
    table-layout: fixed;
}

.textc {
    text-align: center;
}
.bgf6f9fa {
    background: #F6F9FA;
}
.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
body * {
    font-family: "Microsoft YaHei UI";
}
table {
    border-collapse: collapse;
}
* {
    outline: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}
</style>
